exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-booking-jsx": () => import("./../../../src/pages/booking.jsx" /* webpackChunkName: "component---src-pages-booking-jsx" */),
  "component---src-pages-clubs-jsx": () => import("./../../../src/pages/clubs.jsx" /* webpackChunkName: "component---src-pages-clubs-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-minutes-meetings-yaml-year-jsx": () => import("./../../../src/pages/minutes/{MeetingsYaml.year}.jsx" /* webpackChunkName: "component---src-pages-minutes-meetings-yaml-year-jsx" */),
  "component---src-pages-passwordreset-[token]-jsx": () => import("./../../../src/pages/passwordreset/[token].jsx" /* webpackChunkName: "component---src-pages-passwordreset-[token]-jsx" */),
  "component---src-pages-payment-failure-[token]-jsx": () => import("./../../../src/pages/payment/failure/[token].jsx" /* webpackChunkName: "component---src-pages-payment-failure-[token]-jsx" */),
  "component---src-pages-payment-success-[token]-jsx": () => import("./../../../src/pages/payment/success/[token].jsx" /* webpackChunkName: "component---src-pages-payment-success-[token]-jsx" */),
  "component---src-pages-policies-jsx": () => import("./../../../src/pages/policies.jsx" /* webpackChunkName: "component---src-pages-policies-jsx" */),
  "component---src-pages-races-jsx": () => import("./../../../src/pages/races.jsx" /* webpackChunkName: "component---src-pages-races-jsx" */),
  "component---src-pages-races-races-yaml-year-jsx": () => import("./../../../src/pages/races/{RacesYaml.year}.jsx" /* webpackChunkName: "component---src-pages-races-races-yaml-year-jsx" */),
  "component---src-pages-safeguarding-jsx": () => import("./../../../src/pages/safeguarding.jsx" /* webpackChunkName: "component---src-pages-safeguarding-jsx" */),
  "component---src-pages-slopes-jsx": () => import("./../../../src/pages/slopes.jsx" /* webpackChunkName: "component---src-pages-slopes-jsx" */),
  "component---src-pages-sponsors-jsx": () => import("./../../../src/pages/sponsors.jsx" /* webpackChunkName: "component---src-pages-sponsors-jsx" */),
  "component---src-pages-training-jsx": () => import("./../../../src/pages/training.jsx" /* webpackChunkName: "component---src-pages-training-jsx" */),
  "component---src-pages-validateuser-[token]-jsx": () => import("./../../../src/pages/validateuser/[token].jsx" /* webpackChunkName: "component---src-pages-validateuser-[token]-jsx" */)
}

